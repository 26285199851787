// comment to trigger build
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Image,
  LeshenPhoneCTA,
  List,
  LinkButton,
  ListItem,
  Price,
  SplitContent,
  SVGImage,
  useMapi,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'

import { Dropdown, Stack, Columns, Column, Typography } from '@leshen/ui'
import { graphql, navigate, Link as GatsbyLink } from 'gatsby'

import StateCityCtaBar from '../components/StateCityCtaBar'
import getFullStateName from '../utils/getFullStateName'
import siteTheme from '../../theme'

const StateTemplate = ({ data }) => {
  const {
    Meta_Description,
    State,
    Promo_Code,
    Page_Path,
    Meta_Title,
    FAQ_Group,
  } = data.pageInfo.edges[0].node

  const State_Name = getFullStateName(State)

  const { rotatedNumber } = useMapi()

  const passedData = {
    ...data,
    contentfulPage: {
      seo: {
        canonical: '',
        description: Meta_Description,
        robots: 'index,follow',
        title: Meta_Title,
        titleTemplate: '',
      },
      header: data.allContentfulHeader.edges[0].node,
      footer: data.allContentfulFooter.edges[0].node,
      path: Page_Path,
      promoCode: Promo_Code,
      customBreadCrumbs: null,
    },
  }

  const FaqQuestions = {
    'Group 1': [
      {
        title: `Are Brightspeed services offered in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, Brightspeed services are available in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to package Brightspeed internet with phone in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, absolutely. For more information on ordering Brightspeed
              internet and phone services, visit our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the contact number for Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              You can reach a Brightspeed customer service member by calling the
              number below.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `Does Brightspeed offer any internet deals in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              See what Brightspeed deals are available in your area by visiting
              our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does Brightspeed cost in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Brightspeed Internet plans start at $50/mo. Exact pricing varies
              depending on which service you select.
            </Typography>
          </div>
        ),
      },
      {
        title: `What Brightspeed plans are offered in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Go to our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see which Brightspeed plans are available in {State_Name}.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 2': [
      {
        title: `Is Brightspeed available in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, Brightspeed is available in {State_Name}. For more
              information on locations we serve, check out our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink>.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Visit our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see what Brightspeed plans are available in {State_Name}, or
              simply enter your zip code in the search function at the top of
              this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I reach a Brightspeed representative in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Speak with a Brightspeed representative in {State_Name} by calling
              the number below.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `What deals does Brightspeed offer in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Learn more about what deals are available in {State_Name} by
              taking a look at our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much are Brightspeed plans in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Starting at $50 per month, Brightspeed Internet plans in{' '}
              {State_Name} are straightforward and affordable.
            </Typography>
          </div>
        ),
      },
      {
        title: `What Brightspeed packages are offered in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Check out the Brightspeed{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see which packages are offered in {State_Name}.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 3': [
      {
        title: `What Brightspeed plans are offered in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Find out which Brightspeed plans are offered in your area by
              entering your ZIP code in the search function at the top of this
              page.
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to package Brightspeed internet with phone in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, absolutely. For more information on ordering Brightspeed
              internet and phone services, visit our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I contact Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Call the number below to talk with a Brightspeed support
              representative in {State_Name}.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `What deals are available for Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Visit our deals page for more information on which Brightspeed
              deals are offered in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much is Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              The cost of your Brightspeed service depends on which plan you
              select, though some Brightspeed plans cost as low as $50/mo.
            </Typography>
          </div>
        ),
      },
      {
        title: `What packages does Brightspeed offer in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              See which Brightspeed packages are available in {State_Name} by
              visiting our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink>.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 4': [
      {
        title: `Does Brightspeed have internet packages in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, you can package your internet services with Brightspeed Voice
              in {State_Name}. For more information, visit our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Visit our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see what Brightspeed plans are available in {State_Name}, or
              simply enter your zip code in the search function at the top of
              this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I contact Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Contact a Brightspeed representative by calling the number below.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `Does Brightspeed offer any internet deals in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              See what Brightspeed deals are available in your area by visiting
              our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does Brightspeed cost in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              The price of your Brightspeed plan depends on which service you
              order. Input your zip code in the search function at the top of
              this page to see which plans are offered in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Brightspeed offer internet packages in ${State_Name}?`,
        content: (
          <div>
            <Typography variant="feature">
              Yes, Brightspeed offers internet packages to {State_Name}{' '}
              residents.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 5': [
      {
        title: `Does Brightspeed offer services in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, Brightspeed offers services in {State_Name}. Check out the
              availability page to see what Brightspeed plans are available in
              your area.
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to package Brightspeed internet with phone in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, absolutely. For more information on ordering Brightspeed
              internet and phone services, visit our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I reach a Brightspeed support representative in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              You can reach a Brightspeed support representative in
              {State_Name} by calling the number below.
            </Typography>
            <LeshenPhoneCTA color="primary">Call Now</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `What deals does Brightspeed offer in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Learn more about what deals are available in {State_Name} by
              taking a look at our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does it cost to have Brightspeed service in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Brightspeed prices vary depending on which service you order.
              Check out the search function at the top of this page or visit our
              availability page to see which services are available in your
              area.
            </Typography>
          </div>
        ),
      },
      {
        title: `What Brightspeed plans are offered in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Go to our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see which Brightspeed plans are available in {State_Name}.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 6': [
      {
        title: `Is Brightspeed offered in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, you can get Brightspeed service in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Visit our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see what Brightspeed plans are available in {State_Name}, or
              simply enter your zip code in the search function at the top of
              this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the customer service number for Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Call the number below to talk with a Brightspeed customer service
              representative.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `What deals are available for Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Visit our deals page for more information on which Brightspeed
              deals are offered in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much are Brightspeed packages in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Brightspeed prides itself on being affordable and accessible.
              Check out our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see how much services cost in your area.
            </Typography>
          </div>
        ),
      },
      {
        title: `What Brightspeed packages are offered in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Check out the Brightspeed{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see which packages are offered in {State_Name}.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 7': [
      {
        title: `Is ${State_Name} in a Brightspeed serviced area?`,
        content: (
          <div>
            <Typography>
              Yes, Brightspeed provides internet in {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `Am I able to package brightspeed internet with phone in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, absolutely. For more information on ordering Brightspeed
              internet and phone services, visit our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How do I speak with a live person at Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Speak with a member of our support team by calling the number
              below.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `Does Brightspeed offer in any internet deals in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              See what Brightspeed deals are available in your area by visiting
              our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the price of internet service in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              The price of Brightspeed Internet varies based on which plan you
              select. View our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> for
              more details.
            </Typography>
          </div>
        ),
      },
      {
        title: `What packages does Brightspeed offer in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              See which Brightspeed packages are available in {State_Name} by
              visiting our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink>.
            </Typography>
          </div>
        ),
      },
    ],
    'Group 8': [
      {
        title: `Does Brightspeed have service near ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, absolutely. Visit our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see which Brightspeed plans are available near {State_Name}.
            </Typography>
          </div>
        ),
      },
      {
        title: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Visit our{' '}
              <GatsbyLink to="/availability">availability page</GatsbyLink> to
              see what Brightspeed plans are available in {State_Name}, or
              simply enter your zip code in the search function at the top of
              this page.
            </Typography>
          </div>
        ),
      },
      {
        title: `What is the contact number for Brightspeed in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              You can reach a Brightspeed customer service member by calling the
              number below.
            </Typography>
            <LeshenPhoneCTA color="primary">Get Support</LeshenPhoneCTA>
          </div>
        ),
      },
      {
        title: `What deals does Brightspeed offer in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Learn more about what deals are available in {State_Name} by
              taking a look at our deals page.
            </Typography>
          </div>
        ),
      },
      {
        title: `How much does Brightspeed cost in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Brightspeed Internet plans start at $50/mo. Exact pricing varies
              depending on which service you select.
            </Typography>
          </div>
        ),
      },
      {
        title: `Does Brightspeed offer internet packages in ${State_Name}?`,
        content: (
          <div>
            <Typography>
              Yes, Brightspeed offers internet packages to {State_Name}{' '}
              residents.
            </Typography>
          </div>
        ),
      },
    ],
  }

  const faqSchemaData = (shortName) => {
    const schemaFAQ = {
      'Group 1': [
        {
          question: `Are brightspeed services offered in ${State_Name}?`,
          answer: `Yes, Brightspeed services are available in ${State_Name}.`,
        },
        {
          question: `Am I able to package brightspeed internet with phone in ${State_Name}?`,
          answer: `Yes, absolutely. For more information on ordering Brightspeed internet and phone services, visit our deals page.`,
        },
        {
          question: `What is the contact number for brightspeed in ${State_Name}?`,
          answer: `You can reach a Brightspeed customer service member by calling the number below. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `Does brightspeed offer any internet deals in ${State_Name}?`,
          answer: `See what Brightspeed deals are available in your area by visiting our deals page.`,
        },
        {
          question: `How much does brightspeed cost in ${State_Name}?`,
          answer: `Brightspeed Internet plans start at $50/mo. Exact pricing varies depending on which service you select.`,
        },
        {
          question: `what brightspeed plans are offered in ${State_Name}?`,
          answer: `Go to our <a href='/availability'>availability page</a> to see which Brightspeed plans are available in ${State_Name}.`,
        },
      ],
      'Group 2': [
        {
          question: `Is Brightspeed available in ${State_Name}?`,
          answer: `Yes, Brightspeed is available in ${State_Name}?. For more information on locations we serve, check out our <a href='/availability'>availability page</a>.`,
        },
        {
          question: `How can I find out if brightspeed is available near me in ${State_Name}`,
          answer: `Visit our <a href='/availability'>availability page</a>  to see what Brightspeed plans are available in ${State_Name}, or simply enter your zip code in the search function at the top of this page.`,
        },
        {
          question: `How can I reach a Brightspeed representative in ${State_Name}`,
          answer: `Speak with a Brightspeed representative in ${State_Name} by calling the number below. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `What deals does Brightspeed offer in ${State_Name}?`,
          answer: `Learn more about the offers available in ${State_Name} by taking a look at our deals page.`,
        },
        {
          question: `How much are Brightspeed plans in ${State_Name}?`,
          answer: `Starting at $50 per month, Brightspeed Internet plans in ${State_Name} are straightforward and affordable.`,
        },
        {
          question: `What brightspeed packages are offered in ${State_Name}?`,
          answer: `Check out the Brightspeed availability page to see which packages are offered in ${State_Name}`,
        },
      ],
      'Group 3': [
        {
          question: `What Brightspeed plans are offered in ${State_Name}?`,
          answer: `Find out which Brightspeed plans are offered in your area by entering your ZIP code in the search function at the top of this page.`,
        },
        {
          question: `Am I able to package brightspeed internet with phone in  ${State_Name}?`,
          answer: `Yes, absolutely. For more information on ordering Brightspeed internet and phone services, visit our deals page.`,
        },
        {
          question: `How do I contact Brightspeed in ${State_Name}`,
          answer: `Call the number below to talk with a Brightspeed support representative in ${State_Name}. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `What deals are available for Brightspeed in ${State_Name}?`,
          answer: `You can visit our deals page for more information on the
          Brightspeed offers in ${State_Name}`,
        },
        {
          question: `How much is Brightspeed in  ${State_Name}?`,
          answer: `While the cost of your Brightspeed service can depend on the plan you select, Brightspeed plans can start as low as $50/month.`,
        },
        {
          question: `What packages does Brightspeed offer in ${State_Name}?`,
          answer: `See which Brightspeed packages are available in ${State_Name} by visiting our <a href='/availability'>availability page</a>.`,
        },
      ],
      'Group 4': [
        {
          question: `Does Brightspeed have internet packages in ${State_Name}?`,
          answer: `Yes, you can package your internet services with Brightspeed Voice in ${State_Name}. For more information, visit our deals page.`,
        },
        {
          question: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
          answer: `Visit our <a href='/availability'>availability page</a> to see what Brightspeed plans are available in ${State_Name}, or simply enter your zip code in the search function at the top of this page.`,
        },
        {
          question: `How do I contact Brightspeed in ${State_Name}?`,
          answer: `Contact a Brightspeed representative by calling the number below. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `Does Brightspeed offer any internet deals in ${State_Name}?`,
          answer: `See what Brightspeed deals are available in your area by visiting our deals page.`,
        },
        {
          question: `How much does Brightspeed cost in ${State_Name}?`,
          answer: `The price of your Brightspeed plan depends on which service you order. Input your zip code in the search function at the top of this page to see which plans are offered in ${State_Name}.`,
        },
        {
          question: `Does Brightspeed offer internet packages in ${State_Name}?`,
          answer: `Yes, Brightspeed offers internet packages to ${State_Name} residents.`,
        },
      ],
      'Group 5': [
        {
          question: `Does Brightspeed offer services in ${State_Name}?`,
          answer: `Yes, Brightspeed offers services in ${State_Name}. Check out the <a href='/availability'>availability page</a> to see what Brightspeed plans are available in your area.`,
        },
        {
          question: `Am I able to package Brightspeed internet with phone in ${State_Name}?`,
          answer: `Yes, absolutely. For more information on ordering Brightspeed internet and phone services, visit our deals page.`,
        },
        {
          question: `How can I reach a Brightspeed support representative in ${State_Name}?`,
          answer: `You can reach a Brightspeed support representative in ${State_Name} by calling the number below. <a href='tel:${rotatedNumber}'>Call Now ${rotatedNumber}</a>`,
        },
        {
          question: `What deals does Brightspeed offer in ${State_Name}?`,
          answer: `Learn more about what deals are available in ${State_Name} by taking a look at our deals page.`,
        },
        {
          question: `How much does it cost to have Brightspeed service in ${State_Name}?`,
          answer: `Brightspeed prices vary depending on which service you order. Check out the search function at the top of this page or visit our availability page to see which services are available in your area.`,
        },
        {
          question: `What Brightspeed plans are offered in ${State_Name}?`,
          answer: `Go to our <a href='/availability'>availability page</a> to see which Brightspeed plans are available in ${State_Name}.`,
        },
      ],
      'Group 6': [
        {
          question: `Is Brightspeed offered in ${State_Name}?`,
          answer: `Yes, you can get Brightspeed service in  ${State_Name}.`,
        },
        {
          question: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
          answer: `Visit our <a href='/availability'>availability page</a> to see what Brightspeed plans are available in ${State_Name}, or simply enter your zip code in the search function at the top of this page.`,
        },
        {
          question: `What is the customer service number for Brightspeed in ${State_Name}?`,
          answer: `Call the number below to talk with a Brightspeed customer service representative. <a href='tel:${rotatedNumber}'>Call Now ${rotatedNumber}</a>`,
        },
        {
          question: `What deals are available for Brightspeed in ${State_Name}?`,
          answer: `Visit our deals page for more information on which Brightspeed deals are offered in ${State_Name}.`,
        },
        {
          question: `How much are Brightspeed packages in ${State_Name}?`,
          answer: `Brightspeed prides itself on being affordable and accessible. Check out our <a href='/availability'>availability page</a> to see how much services cost in your area.`,
        },
        {
          question: `What Brightspeed packages are offered in ${State_Name}?`,
          answer: `Check out the Brightspeed <a href='/availability'>availability page</a> to see which packages are offered in ${State_Name}.`,
        },
      ],
      'Group 7': [
        {
          question: `Is ${State_Name} in a Brightspeed serviced area?`,
          answer: `Yes, Brightspeed provides internet in ${State_Name}.`,
        },
        {
          question: `Am I able to package brightspeed internet with phone in ${State_Name}?`,
          answer: `Yes, absolutely. For more information on ordering Brightspeed internet and phone services, visit our deals page.`,
        },
        {
          question: `How do I speak with a live person at Brightspeed in ${State_Name}?`,
          answer: `Speak with a member of our support team by calling the number below. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `Does Brightspeed offer in any internet deals in ${State_Name}?`,
          answer: `See what Brightspeed deals are available in your area by visiting our deals page.`,
        },
        {
          question: `What is the price of internet service in ${State_Name}?`,
          answer: `The price of Brightspeed Internet varies based on which plan you select. View our <a href='/availability'>availability page</a> for more details.`,
        },
        {
          question: `What packages does Brightspeed offer in ${State_Name}?`,
          answer: `See which Brightspeed packages are available in ${State_Name} by visiting our <a href='/availability'>availability page</a>.`,
        },
      ],
      'Group 8': [
        {
          question: `Does Brightspeed have service near ${State_Name}?`,
          answer: `Yes, absolutely. Visit our <a href='/availability'>availability page</a> to see which Brightspeed plans are available near ${State_Name}.`,
        },
        {
          question: `How can I find out if Brightspeed is available near me in ${State_Name}?`,
          answer: `Visit our <a href='/availability'>availability page</a> to see what Brightspeed plans are available in ${State_Name}, or simply enter your zip code in the search function at the top of this page.`,
        },
        {
          question: `What is the contact number for Brightspeed in ${State_Name}?`,
          answer: `You can reach a Brightspeed customer service member by calling the number below. <a href='tel:${rotatedNumber}'>Get Support ${rotatedNumber}</a>`,
        },
        {
          question: `What deals does Brightspeed offer in ${State_Name}?`,
          answer: `Learn more about what deals are available in ${State_Name} by taking a look at our deals page.`,
        },
        {
          question: `How much does Brightspeed cost in ${State_Name}?`,
          answer: `Brightspeed Internet plans start at $50/mo. Exact pricing varies depending on which service you select.`,
        },
        {
          question: `Does Brightspeed offer internet packages in ${State_Name}?`,
          answer: `Yes, Brightspeed offers internet packages to ${State_Name} residents.`,
        },
      ],
    }
    return schemaFAQ?.[shortName]
  }

  const generateQuestion = (question) => {
    return `{
      "@type": "Question",
      "name": "${question.question}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${question.answer}"
      }
    }`
  }

  const generateSchema = () => {
    const schema = `
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            ${faqSchemaData(FAQ_Group)?.map((current) =>
              generateQuestion(current)
            )}
          ]
        }
        `
    return schema
  }

  const cityDropdownData = data.cities.edges
    .map((current) => ({
      label: current.node.City,
      value: current.node.Page_Path,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Layout
      data={passedData}
      main={
        <>
          <Helmet>
            <script type="application/ld+json">{`${generateSchema()}`}</script>
          </Helmet>
          <Billboard
            customLink={GatsbyLink}
            variant="full"
            backgroundColor="Primary"
            mainContent={
              <>
                <Typography variant="h1">
                  Switch to Brightspeed Internet in {State_Name}
                </Typography>
                <Price
                  amount="50"
                  before="Starting at"
                  style={{ marginBottom: '16px' }}
                >
                  <Typography
                    variant="legal"
                    style={{ marginTop: '0', marginBottom: '0' }}
                  >
                    Plus taxes. Autopay required. Installation charge may apply.
                  </Typography>
                </Price>
                <List style={{ marginBottom: '0' }}>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>No contracts or termination fees</Typography>
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h5" rendersAs="span">
                      <Typography>Reliable, secure network</Typography>
                    </Typography>
                  </ListItem>
                </List>

                <AvailabilityForm
                  label="ZIP Code"
                  buttonText="Check availability"
                  buttonColor="dark"
                  butttonBackgroundColor="black"
                />
              </>
            }
            image={
              <Image
                data={data.couchComputerImage.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
          />
          <StateCityCtaBar />
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  Brightspeed availability in {State_Name}
                </Typography>
                <Typography>
                  Brightspeed provides internet and voice services to many
                  locations across {State_Name}. See whether our services are
                  available in your area by viewing the dropdown menu below.
                </Typography>
              </>
            }
            alignMainContent="center"
          >
            <Stack alignMainContent="center">
              <Dropdown
                options={cityDropdownData}
                onChange={(e) => navigate(e.value)}
                placeholderText="Select City"
              />
              {cityDropdownData?.map((current) => (
                <StyledLink href={current.value} key={current.value}>
                  {current.label}
                </StyledLink>
              ))}
            </Stack>
          </VariableContent>
          <SplitContent
            customLink={GatsbyLink}
            image={
              <Image
                data={data.childrenTabletImage.cloudinary[0].gatsbyImageData}
                isImageCritical
              />
            }
            mainContent={
              <>
                <Typography variant="h2">
                  What You Get With Brightspeed in {State_Name}
                </Typography>
                <Typography>
                  {`Brightspeed provides fast, dependable internet across ${State_Name} including:`}
                </Typography>
                <List>
                  <ListItem>
                    <Typography>
                      A straightforward billing structure with no extra bundling
                      costs
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      Stable service and speeds that support your lifestyle
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      A dedicated customer service team that answers your
                      questions as quickly as possible
                    </Typography>
                  </ListItem>
                </List>
              </>
            }
          />
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">Other Brightspeed Benefits</Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns cardStyled leftAligned={false}>
                <Column>
                  <SVGImage
                    svg={data.phoneIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.phoneIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                  <Typography variant="h4">Voice</Typography>
                  <Typography>
                    Get Caller ID, voicemail, 3-way calling, and more with
                    Brightspeed home phone plans.
                  </Typography>
                </Column>
                <Column>
                  <SVGImage
                    svg={data.customerIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.phoneIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                  <Typography variant="h4">Customer Service</Typography>
                  <Typography>
                    A sizable Brightspeed customer care team means you’ll get
                    quick and dedicated assistance whenever you need it.
                  </Typography>
                </Column>
                <Column>
                  <SVGImage
                    svg={data.blogIcon.cloudinary[0].svg.code}
                    presentationWidth={
                      data.phoneIcon.cloudinary[0].svg.presentationWidth
                    }
                  />
                  <Typography variant="h4">Blog</Typography>
                  <Typography>
                    The Brightspeed blog offers guides on ideal internet speeds,
                    online safety, and more.
                  </Typography>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            alignMainContent="center"
            style={{ backgroundColor: siteTheme.colors.primary }}
            mainContent={
              <>
                <Typography variant="h2">
                  Moving Your Internet Service in {State_Name}
                </Typography>
                <Typography>
                  Need assistance with moving your internet to your new home?
                  Brightspeed has you covered.
                </Typography>
              </>
            }
          >
            <Stack spacing="xxl">
              <Columns cardStyled leftAligned={false}>
                <Column>
                  <Typography variant="h4">
                    Are you a current Brightspeed customer?
                  </Typography>
                  <Typography>
                    Our customer service team takes the stress out of moving,
                    providing support throughout your move.
                  </Typography>
                  <LeshenPhoneCTA color="primary">Call Now</LeshenPhoneCTA>
                </Column>
                <Column>
                  <Typography variant="h4">
                    Do you need internet in your new home?
                  </Typography>
                  <Typography>
                    Moving to {State_Name} soon? Get new internet service with
                    Brightspeed today.
                  </Typography>
                  <LinkButton to="/internet"> View Internet Plans</LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
          <VariableContent
            mainContent={
              <Typography variant="h2">Frequently Asked Questions</Typography>
            }
            alignMainContent="center"
          >
            <Stack spacing="xxl" alignMainContent="center">
              <Accordion items={FaqQuestions[FAQ_Group]} />
            </Stack>
          </VariableContent>
        </>
      }
    />
  )
}

StateTemplate.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default StateTemplate

const StyledLink = styled.a`
  display: none !important;
`

export const query = graphql`
  query StateTemplateQuery($pagePath: String!, $stateAbbreviation: String!) {
    site {
      ...LeshenMetadata
    }
    pageInfo: allDatasetManagerBrightspeedAoa(
      filter: { Page_Path: { eq: $pagePath } }
    ) {
      edges {
        node {
          FAQ_Group
          State
          Promo_Code
          Page_Path
          Meta_Title
          Meta_Description
        }
      }
    }
    couchComputerImage: contentfulMedia(
      contentful_id: { eq: "1Yj8ljZfCC5wjYWJu5OFa1" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED, width: 2560)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    allContentfulHeader(
      filter: {
        identifier: {}
        id: { eq: "72ec5eae-ce2a-5a26-ad86-20a9d89fac4f" }
      }
    ) {
      edges {
        node {
          id
          __typename
          identifier
          navigationPosition
          hideLocation
          navigationPosition
          headerBackgroundColor
          headerTextColor
          topBarBackgroundColor
          topBarTextColor
          navigationBackgroundColor
          navigationTextColor
          hoverBackgroundColor
          hoverTextColor
          logoLink
          borderColor
          borderHeight
          textBlockAlignment
          textBlock {
            raw
          }
          linkGroups {
            ...LeshenLinkGroup
            ...LeshenNavigationLink
          }
          topBarLinks {
            ...LeshenNavigationLink
          }
          callToActions {
            ... on ContentfulButton {
              ...LeshenButton
            }
            ... on ContentfulPhoneNumber {
              ...LeshenPhoneNumber
            }
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { id: { eq: "49d3a46c-fe71-5c40-baaa-0f2f38a3d047" } }
    ) {
      edges {
        node {
          __typename
          id
          identifier
          linkGroups {
            ...LeshenLinkGroup
          }
          logo {
            cloudinary {
              gatsbyImageData(placeholder: BLURRED)
              svg {
                code
                presentationWidth
              }
              context {
                custom {
                  alt
                  caption
                }
              }
            }
          }
          disclaimers {
            ...LeshenDisclaimer
          }
          navigationBackgroundColor
          navigationTextColor
          disclaimerBackgroundColor
          disclaimerTextColor
          logoLink
          logoBackgroundColor
          logoTextColor
          logoTopBorder
          logoBottomBorder
        }
      }
    }
    cities: allDatasetManagerBrightspeedAoa(
      filter: { State: { eq: $stateAbbreviation }, Area_Type: { eq: "city" } }
    ) {
      edges {
        node {
          City
          Page_Path
        }
      }
    }
    childrenTabletImage: contentfulMedia(
      contentful_id: { eq: "6hV6RGlP3gHEY4GSaNshaQ" }
    ) {
      cloudinary {
        gatsbyImageData(placeholder: BLURRED)
        context {
          custom {
            alt
            caption
          }
        }
      }
    }
    phoneIcon: contentfulMedia(
      contentful_id: { eq: "75oquD8iz9yb6Tp9xcSX9T" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    customerIcon: contentfulMedia(
      contentful_id: { eq: "6bqqzUXtSCAXCvopFBTQY5" }
    ) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
    blogIcon: contentfulMedia(contentful_id: { eq: "4fvj1e0zb18tsnEMB0jVIm" }) {
      cloudinary {
        svg {
          code
          presentationWidth
        }
      }
    }
  }
`
